<h1 mat-dialog-title class="dialog-title">My organization</h1>
<div mat-dialog-content>
  @if ((organizations$ | async)?.length > 1) {
    <planning-poker-organization-selector label="Select active organization" />
  }
  @if (checklist$ | async; as checklist) {
    @if (organization && !checklist.allCompleted && isOrganizationCreator) {
      <mat-card class="checklist-banner" appearance="outline" @fadeAnimation>
        <mat-card-content class="checklist">
          <h3>
            Just a few more steps to get the most out of your organization:
          </h3>
          <ul>
            <li>
              <mat-icon>check</mat-icon>
              <span>Create your organization</span>
            </li>
            <li>
              @if (!checklist.items.logoUploaded) {
                <mat-icon>pending_actions</mat-icon>
              } @else {
                <mat-icon>check</mat-icon>
              }
              <ng-template #check><mat-icon>check</mat-icon></ng-template>
              <span>Upload your logo to customize the app</span>
            </li>
            <li>
              @if (!checklist.items.colleaguesInvited) {
                <mat-icon>pending_actions</mat-icon>
              } @else {
                <mat-icon>check</mat-icon>
              }
              <ng-template #check><mat-icon>check</mat-icon></ng-template>
              <span>Expand your organization with colleagues</span>
            </li>
          </ul>
        </mat-card-content>
      </mat-card>
    }
  }
  @if (organization !== null) {
    @if (!organization && showIntro) {
      <div class="empty-state">
        <h5 class="section-title">Why create an organization?</h5>
        <p>
          With an organization, you can create rooms just for your teammates,
          personalize the app with your logo and much more!
        </p>
        @if ((authService.user | async)?.isAnonymous) {
          <button
            mat-flat-button
            color="primary"
            (click)="openCreateAccountModal()"
            class="create-account-button">
            Create an account first
          </button>
        }
        <button
          mat-flat-button
          color="primary"
          (click)="createEmptyOrganization()"
          [disabled]="(authService.user | async)?.isAnonymous">
          Let's set it up!
        </button>
      </div>
    }
    @if (organization || !showIntro) {
      @if (isOrganizationCreator) {
        <mat-tab-group
          class="custom-tab-group"
          mat-align-tabs="center"
          [dynamicHeight]="true"
          backgroundColor="primary">
          <mat-tab label="Details">
            <div class="tab-content">
              <div class="tab-section">
                <form class="organization-form" [formGroup]="organizationForm">
                  <h5 class="section-title">
                    <mat-icon>apartment</mat-icon> Organization's name
                  </h5>
                  <mat-form-field class="form-input" appearance="outline">
                    <input
                      matInput
                      formControlName="name"
                      placeholder="Best Company Ltd."
                      [disabled]="!isOrganizationCreator" />
                    <mat-hint
                      >Set your company's name here, like: "Great Code
                      LLC"</mat-hint
                    >
                  </mat-form-field>
                </form>
                @if (organization || !showIntro) {
                  <button
                    mat-stroked-button
                    color="primary"
                    (click)="saveOrganization()"
                    class="save-button"
                    [disabled]="
                      organization?.name ===
                        organizationForm.controls.name.value ||
                      !isOrganizationCreator
                    ">
                    Update
                  </button>
                }
                <h5 class="section-title">
                  <mat-icon>diamond</mat-icon> Organization's logo
                </h5>
                <small
                  >The logo will be used to personalize the welcome screen of
                  the app. Select an image that fits nicely in a square logo
                  container, similar to Planning Poker's own logo.</small
                >
                @if (organization?.logoUrl) {
                  <div class="logo-container">
                    <img class="logo" [src]="organization?.logoUrl" />
                    <button
                      mat-button
                      (click)="removeLogo()"
                      [disabled]="!isOrganizationCreator">
                      Remove logo
                    </button>
                  </div>
                }
                <div class="file-upload">
                  @if (!organization?.logoUrl) {
                    <file-upload-drag-drop
                      (onFileDropped)="
                        onLogoDropped($event)
                      "></file-upload-drag-drop>
                  }
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Members">
            <div class="tab-content">
              <div class="tab-section">
                @if (organization?.memberIds) {
                  <div class="members-section">
                    <h5 class="section-title">
                      <mat-icon>people</mat-icon> Manage members
                    </h5>
                    <ul class="members-list">
                      @for (member of members$ | async; track member.id) {
                        <li>
                          <div class="member">
                            <div class="member-meta">
                              <span class="avatar">
                                <div class="avatar-text">
                                  {{ member.displayName?.charAt(0) }}
                                </div>
                              </span>
                              <div>
                                <h3>
                                  {{ member.displayName }}
                                </h3>
                                @if (member.id === organization?.createdById) {
                                  <small>Admin</small>
                                }
                              </div>
                            </div>
                            <button
                              mat-icon-button
                              [matMenuTriggerFor]="menu"
                              class="member-menu-button">
                              <mat-icon>more_horiz</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                              <button
                                mat-menu-item
                                [disabled]="
                                  member.id === organization?.createdById
                                "
                                (click)="removeFromOrganization(member.id)">
                                <mat-icon>group_remove</mat-icon> Remove from
                                organization
                              </button>
                            </mat-menu>
                          </div>
                        </li>
                      }
                    </ul>
                  </div>
                }
                <h5 class="section-title">
                  <mat-icon>mail</mat-icon> Invite members by email
                </h5>
                <mat-form-field
                  class="form-input invite-email"
                  appearance="outline"
                  subscriptSizing="dynamic">
                  <mat-chip-grid #chipGrid aria-label="Enter fruits">
                    @for (email of emailFormValues; track email) {
                      <mat-chip-row
                        (removed)="removeEmailFromForm(email)"
                        [editable]="true"
                        (edited)="editEmailInForm(email, $event)"
                        [aria-description]="'press enter to edit ' + email">
                        {{ email }}
                        <button
                          matChipRemove
                          [attr.aria-label]="'remove ' + email">
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </mat-chip-row>
                    }
                    <input
                      placeholder="Add one or multiple emails separated by commas"
                      [matChipInputFor]="chipGrid"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      [matChipInputAddOnBlur]="addOnBlur"
                      (matChipInputTokenEnd)="addEmailToForm($event)"
                      [disabled]="!isOrganizationCreator" />
                  </mat-chip-grid>
                  <button
                    mat-icon-button
                    matSuffix
                    (click)="inviteMember()"
                    [attr.aria-label]="'Invite members'"
                    [disabled]="
                      !emailFormValues.length || !isOrganizationCreator
                    "
                    color="primary">
                    <mat-icon>send</mat-icon>
                  </button>
                  <mat-hint>
                    @if (inviteProgress | async; as inviteProgress) {
                      {{ inviteProgress }}
                    }
                  </mat-hint>
                </mat-form-field>
                @if (invitations$ | async; as invitations) {
                  @if (invitations.length) {
                    <h5 class="section-title">
                      <mat-icon>send</mat-icon> Pending invites
                    </h5>
                    <mat-chip-listbox aria-label="Sent invitations">
                      @for (invite of invitations; track invite) {
                        <mat-chip
                          [matTooltip]="invite.tooltip"
                          (removed)="removeInvite(invite)">
                          <div class="invite-chip">
                            @if (invite.emailStatus === 'success') {
                              <mat-icon matTooltip="Invitation email sent"
                                >check</mat-icon
                              >
                            }
                            @if (invite.emailStatus === 'pending') {
                              <mat-icon matTooltip="Waiting for delivery"
                                >hourglass_empty</mat-icon
                              >
                            }
                            @if (invite.emailStatus === 'error') {
                              <mat-icon matTooltip="Mail could not be delivered"
                                >warning</mat-icon
                              >
                            }
                            {{ invite.invitationEmail }}
                          </div>
                          <button
                            matChipRemove
                            aria-label="Cancel invite"
                            [matTooltip]="'Remove invitation'">
                            <mat-icon>cancel</mat-icon>
                          </button>
                        </mat-chip>
                      }
                    </mat-chip-listbox>
                  }
                }
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Credits">
            <div class="tab-content">
              <div class="tab-section">
                <h5 class="section-title">
                  <mat-icon>toll</mat-icon> Your org's credits
                </h5>
                <p>
                  Organization credits can be used by all members of your
                  organization. This allows flexible, usage-based pricing for
                  larger teams without the hassle of individual subscriptions.
                </p>
                @if (organizationCredits()) {
                  <div class="credits-container">
                    @if (organizationCredits().total === 0) {
                      <span
                        >You have not purchased any organization credits.</span
                      >
                    } @else {
                      <mat-progress-bar
                        mode="determinate"
                        [value]="
                          (organizationCredits().available /
                            organizationCredits().total) *
                          100
                        " />
                      <span class="credits-progress-text">
                        {{ organizationCredits().available }} credits available
                        out of {{ organizationCredits().total }}.
                      </span>
                    }
                    <button
                      class="credits-buy-button"
                      mat-flat-button
                      color="primary"
                      (click)="purchaseCredits()">
                      Purchase credits
                    </button>
                  </div>
                } @else {
                  <mat-spinner diameter="30"></mat-spinner>
                }
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      } @else {
        <div class="tab-content">
          <div class="tab-section">
            <h5 class="section-title">
              <mat-icon>apartment</mat-icon> {{ organization?.name }}
            </h5>
            <p>
              You are a member of this organization. You can view the
              organization's details, join rooms created for this org & use the
              organization's credits.
            </p>
            <p>
              Members:
              <mat-chip-set>
                @for (member of members$ | async; track member.id) {
                  <mat-chip>
                    {{ member.displayName }}
                  </mat-chip>
                }
              </mat-chip-set>
            </p>
            <p class="credits-text">
              Organization credits:
              @if (organizationCredits()) {
                <strong
                  >{{ organizationCredits()?.available }} out of
                  {{ organizationCredits()?.total }}</strong
                >
                <button mat-button (click)="purchaseCredits()">
                  Buy credits
                </button>
              } @else {
                <mat-spinner diameter="30"></mat-spinner>
              }
            </p>
          </div>
        </div>
      }
    }
  } @else {
    <div class="loading">
      <mat-progress-spinner
        mode="indeterminate"
        [diameter]="30"></mat-progress-spinner>
    </div>
  }
</div>
<div mat-dialog-actions>
  <button mat-flat-button mat-dialog-close>Close</button>
</div>
<ng-template #loading>
  <div class="loading">
    <mat-progress-spinner
      mode="indeterminate"
      [diameter]="30"></mat-progress-spinner>
  </div>
</ng-template>
