<h1 mat-dialog-title class="dialog-title">Integrations</h1>
<div mat-dialog-content>
  <p>
    Connect Planning Poker with the tools you already know to improve your
    estimation process.
  </p>
  <div class="tab-section">
    <div class="integrations-list">
      @if (['teams', 'web'].includes(config.runningIn)) {
        <div class="integration">
          <div class="integration-main">
            <div class="details">
              <div class="title">
                <img class="integration-logo" src="/assets/teams_logo.png" />
                <h4>Teams</h4>
              </div>
              <p>Use Planning Poker directly in the Teams app.</p>
            </div>
            <div class="actions">
              <a
                mat-flat-button
                color="primary"
                [disabled]="config.runningIn === 'teams'"
                href="https://appsource.microsoft.com/en-us/product/office/WA200005858?tab=Overview"
                target="_blank"
                rel="noreferrer">
                {{ config.runningIn === 'teams' ? 'Installed' : 'Install' }}
              </a>
            </div>
          </div>
        </div>
      }
      @if (['zoom', 'web'].includes(config.runningIn)) {
        <div class="integration">
          <div class="integration-main">
            <div class="details">
              <div class="title">
                <img class="integration-logo" src="/assets/zoom.png" />
                <h4>Zoom</h4>
              </div>
              <p>Use Planning Poker directly in the Zoom Meetings app.</p>
            </div>
            <div class="actions">
              <a
                mat-flat-button
                color="primary"
                [disabled]="config.runningIn === 'zoom'"
                href="https://planningpoker.live/api/installZoomApp"
                target="_blank"
                rel="noreferrer">
                {{ config.runningIn === 'zoom' ? 'Installed' : 'Install' }}
              </a>
            </div>
          </div>
        </div>
      }
      @if (['webex', 'web'].includes(config.runningIn)) {
        <div class="integration">
          <div class="integration-main">
            <div class="details">
              <div class="title">
                <img class="integration-logo" src="/assets/webex_logo.png" />
                <h4>Webex</h4>
              </div>
              <p>Use Planning Poker directly in the Webex Meetings app.</p>
            </div>
            <div class="actions">
              <a
                mat-flat-button
                color="primary"
                [disabled]="config.runningIn === 'webex'"
                href="https://apphub.webex.com/applications/planning-poker-planningpoker-live"
                target="_blank"
                rel="noreferrer">
                {{ config.runningIn === 'webex' ? 'Installed' : 'Install' }}
              </a>
            </div>
          </div>
        </div>
      }
      @if (['meet', 'web'].includes(config.runningIn)) {
        <div class="integration">
          <div class="integration-main">
            <div class="details">
              <div class="title">
                <img class="integration-logo" src="/assets/meet_logo.png" />
                <h4>Google Meet™</h4>
              </div>
              <p>Use Planning Poker directly in the Google Meet™ app.</p>
            </div>
            <div class="actions">
              <a
                mat-flat-button
                color="primary"
                [disabled]="config.runningIn === 'meet'"
                href="https://workspace.google.com/marketplace/app/planningpokerlive/417578634660"
                target="_blank"
                rel="noreferrer">
                {{ config.runningIn === 'meet' ? 'Installed' : 'Install' }}
              </a>
            </div>
          </div>
        </div>
      }
      <div class="integration">
        <div class="integration-main">
          <div class="details">
            <div class="title">
              <img class="integration-logo" src="/assets/jira.png" />
              <h4>Jira</h4>
            </div>
            <p>
              Select tickets from your recent history and search right from the
              topic editor.
            </p>
          </div>
          <div class="actions">
            <button mat-flat-button color="primary" (click)="startJiraAuth()">
              {{ (jiraIntegration$ | async) ? 'Reconnect' : 'Connect' }}
            </button>
          </div>
        </div>
        <div class="integration-preferences">
          @if ((jiraIntegration$ | async)?.jiraResources; as projectList) {
            <mat-radio-group
              aria-label="Select the active Jira project"
              class="jira-list">
              @for (project of projectList; track project) {
                <mat-radio-button
                  [value]="project"
                  color="primary"
                  [checked]="project.active"
                  (click)="
                    projectList.length > 1 && onJiraProjectSelected(project)
                  "
                  ><span class="jira-list-label">
                    {{ project.url }}
                  </span>
                </mat-radio-button>
                <div class="jira-actions">
                  <button mat-button (click)="configureJiraResource(project)">
                    Configure
                  </button>
                  <button
                    mat-button
                    (click)="
                      onJiraProjectRemoveClicked(project);
                      $event.stopPropagation()
                    ">
                    Remove
                  </button>
                </div>
              }
            </mat-radio-group>
          }
        </div>
      </div>
      <div class="integration">
        <div class="integration-main">
          <div class="details">
            <div class="title">
              <img class="integration-logo" src="/assets/linear.png" />
              <h4>Linear</h4>
            </div>
            <p>
              Select tickets from your recent history and search right from the
              topic editor.
            </p>
          </div>
          <div class="actions">
            <button mat-flat-button color="primary" (click)="startLinearAuth()">
              {{ (linearIntegration$ | async) ? 'Reconnect' : 'Connect' }}
            </button>
          </div>
        </div>
        <div class="integration-preferences">
          @if (linearIntegration$ | async) {
            <button mat-button (click)="removeLinearIntegration()">
              Remove
            </button>
          }
        </div>
      </div>
      <div class="integration">
        <div class="integration-main">
          <div class="details">
            <div class="title">
              <img class="integration-logo" src="/assets/slack.png" />
              <h4>Slack</h4>
            </div>
            <p>
              Instantly create and share rooms with a single Slack command.
              Simplify your workflow and save time.
            </p>
          </div>
          <div class="actions">
            <button mat-flat-button color="primary" (click)="startSlackAuth()">
              {{ slackIntegration() ? 'Reconnect' : 'Connect' }}
            </button>
          </div>
        </div>
        <div class="integration-preferences">
          @if (slackIntegration()) {
            <button mat-button (click)="removeSlackIntegration()">
              Remove
            </button>
          }
        </div>
      </div>
    </div>
  </div>
</div>
