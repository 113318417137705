<div class="content" [class.modal]="pageMode === 'modal'">
  @if (pageMode === 'modal') {
    <div class="close-button">
      <button mat-button mat-dialog-close>
        <mat-icon>arrow_back</mat-icon>Back
      </button>
    </div>
  }
  <div class="details">
    <div class="details-inner">
      <h1 class="title">
        Upgrade to Planning Poker Premium for the best experience
      </h1>
      <p class="description">
        By subscribing now, you'll have access to all of these premium features
        and support the ongoing maintenance and improvement of our open-source
        project.
      </p>
      <ul class="premium-features-list">
        <li>
          <div class="icon">
            <mat-icon>security</mat-icon>
          </div>
          <div class="feature-details">
            <h5>Private rooms</h5>
            Create private rooms protected by a password
          </div>
        </li>
        <li>
          <div class="icon">
            <mat-icon>manage_history</mat-icon>
          </div>
          <div class="feature-details">
            <h5>Previous sessions</h5>
            Unlock your full planning history
          </div>
        </li>
        <li>
          <div class="icon">
            <mat-icon>style</mat-icon>
          </div>
          <div class="feature-details">
            <h5>Saved card sets</h5>
            Reuse custom card sets across meetings
          </div>
        </li>
        <li>
          <div class="icon">
            <mat-icon>upload</mat-icon>
          </div>
          <div class="feature-details">
            <h5>Export to Jira</h5>
            Save votes back to Jira with a single click
          </div>
        </li>
        <li>
          <div class="icon">
            <mat-icon>image</mat-icon>
          </div>
          <div class="feature-details">
            <h5>Remove ads</h5>
            Ads will not display for you and rooms you create
          </div>
        </li>
        <li>
          <div class="icon">
            <mat-icon>settings_input_composite</mat-icon>
          </div>
          <div class="feature-details">
            <h5>Custom permissions</h5>
            Adjust room member permissions to your preferences
          </div>
        </li>
        <li>
          <div class="icon">
            <mat-icon>auto_fix_normal</mat-icon>
          </div>
          <div class="feature-details">
            <h5>Unlimited AI - PokerBot</h5>
            Use the AI Scrum Master with no limits
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="sidebar">
    <mat-card appearance="outlined" class="get-premium-card">
      <mat-card-content>
        <h2>Subscribe to Premium today</h2>
        <p class="premium-description">
          Start with a 14 day free-trial, so you can explore all premium
          features. Teams only need a single license for their facilitator.
        </p>
        <h3 class="plan-title">
          Premium Plan for a SCRUM Master and their team
        </h3>
        <div class="plan">
          <div class="monthly">
            <div class="logo-container">
              <img class="logo" src="/assets/logo.png" />
            </div>
            <h2
              class="monthly-price"
              matTooltip="The subscription allows the facilitator to create rooms with Premium benefits. One license is enough for small/medium sized organizations.">
              €15 <span class="light">/team/month</span>
            </h2>
            <span class="free-trial">14 days free</span>
          </div>
          <div class="yearly">
            <span
              ><span class="saving">Save €30</span> with annual billing</span
            >
            <span class="yearly-price">€150 /year</span>
          </div>
        </div>
        <div class="stripe">
          <p class="description">Secure global payment and invoicing with</p>
          <img src="/assets/stripe.png" class="stripe-logo" />
        </div>
        <button
          mat-flat-button
          color="primary"
          (click)="subscribeToPremium()"
          [disabled]="isPremium$ | async"
          class="stripe-button">
          {{
            isLoadingStripe ? 'Redirecting to Stripe...' : 'Start free trial'
          }}
        </button>
        @if (promotionCodeFromParams) {
          <p class="promotion discount">
            Your promotion code will be automatically applied at checkout.
          </p>
        }
        <button mat-button (click)="getQuote()">
          Need a custom quote? Get in touch
        </button>
      </mat-card-content>
    </mat-card>
  </div>
</div>
